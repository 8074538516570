var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('setting-layout',{attrs:{"title":_vm.$t('pages.user.setting.creator.promotion.title')}},[_c('v-container',[_c('div',[_vm._v(" "+_vm._s(_vm.$t("pages.user.setting.creator.promotion.description"))+" ")]),_c('div',{staticClass:"text-h5 mt-6"},[_vm._v("Time")]),_c('div',{staticClass:"cards mt-4"},[_c('div',{staticClass:"card",class:{
                    'card--active': _vm.boost.minutes === _vm.MINUTES_OPTIONS['5'],
                    'card--disabled': !!_vm.currentBoost,
                },on:{"click":function($event){!_vm.currentBoost
                        ? (_vm.boost.minutes = _vm.MINUTES_OPTIONS['5'])
                        : null}}},[_c('div',{staticClass:"card__icons"},[_c('IconRocket')],1),_c('div',{staticClass:"card__title"},[_vm._v("5 minutes")]),_c('div',{staticClass:"card__price"},[_vm._v("2,99 $")]),_c('div',{staticClass:"card__discount",staticStyle:{"opacity":"0.5"}},[_c('div',[_vm._v("-0%")])])]),_c('div',{staticClass:"card",class:{
                    'card--active': _vm.boost.minutes === _vm.MINUTES_OPTIONS['15'],
                    'card--disabled': !!_vm.currentBoost,
                },on:{"click":function($event){!_vm.currentBoost
                        ? (_vm.boost.minutes = _vm.MINUTES_OPTIONS['15'])
                        : null}}},[_c('div',{staticClass:"card__icons"},[_c('IconRocket'),_c('IconRocket')],1),_c('div',{staticClass:"card__title"},[_vm._v("15 minutes")]),_c('div',{staticClass:"card__price"},[_vm._v("6,99 $")]),_c('div',{staticClass:"card__discount"},[_c('div',[_vm._v("-23%")])])]),_c('div',{staticClass:"card",class:{
                    'card--active': _vm.boost.minutes === _vm.MINUTES_OPTIONS['30'],
                    'card--disabled': !!_vm.currentBoost,
                },on:{"click":function($event){!_vm.currentBoost
                        ? (_vm.boost.minutes = _vm.MINUTES_OPTIONS['30'])
                        : null}}},[_c('div',{staticClass:"card__icons"},[_c('IconRocket'),_c('IconRocket'),_c('IconRocket')],1),_c('div',{staticClass:"card__title"},[_vm._v("30 minutes")]),_c('div',{staticClass:"card__price"},[_vm._v("9,99 $")]),_c('div',{staticClass:"card__discount"},[_c('div',[_vm._v("-45%")])])])]),_c('div',{staticClass:"text-h5 mt-6",on:{"click":function($event){_vm.showTagsInfo = !_vm.showTagsInfo}}},[_vm._v(" Tags "),_c('IconInfo',{staticStyle:{"margin-bottom":"-2px"}})],1),(_vm.showTagsInfo)?_c('div',{staticClass:"mb-2 mt-1"},[_vm._v(" Select the tags that you want to target ")]):_vm._e(),(!_vm.currentBoost)?_c('v-expansion-panels',[_c('checkboxes',{staticClass:"mt-5",attrs:{"title":_vm.$t('dialogs.tags'),"hint":_vm.$t('dialogs.boost_tags_hint'),"data":_vm.TAG_OPTIONS,"max-count":10,"display-tag-title":false},on:{"change":_vm.checkSelectedTags},model:{value:(_vm.boost.tags),callback:function ($$v) {_vm.$set(_vm.boost, "tags", $$v)},expression:"boost.tags"}})],1):_c('div',{staticClass:"tags"},_vm._l((_vm.boost.tags),function(tag){return _c('div',{key:tag.id,staticClass:"tags__item"},[_vm._v(" "+_vm._s(tag.title)+" ")])}),0),(!_vm.hasTag && !_vm.currentBoost)?_c('div',[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")]),_vm._v(" "+_vm._s(_vm.$t("pages.user.setting.creator.promotion.notags"))+" ")])]):_vm._e(),(!_vm.currentBoost)?_c('gradient-button',{staticClass:"px-2",staticStyle:{"margin-top":"36px"},attrs:{"disable":!_vm.hasTag},on:{"click":_vm.handleSubmit}},[_vm._v(" Boost ")]):_c('div',{staticClass:"mt-6"},[_c('div',{staticClass:"text-center",staticStyle:{"font-weight":"500"}},[_vm._v(" Boost ends in ")]),_c('div',{staticClass:"text-h2 orange--text text-center mt-2"},[_vm._v(" "+_vm._s(_vm.countdown)+" ")])])],1),(_vm.showErrorDialog)?_c('inform-dialog',{attrs:{"text":_vm.dialogText},on:{"close":function($event){_vm.showErrorDialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }